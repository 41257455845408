// USER VARIABLES SECTION

$accent: #009cdf // Accent Color
$fontsize: 16px // Body Font Size
$textcolor: #000 // Text Color
$dark: #29292B
$white: #fff
$lineheight: 1.52 // Document Line Height
$userfont: Source Sans Pro, sans-serif // User Font Family
$accentfont: Montserrat, sans-serif
$systemfont: -apple-system, BlinkMacSystemFont, Arial, sans-serif // System Font Family

// Bootstrap compatible gutter variable => $gutter
$grid-gutter-width: 30px
$gutter: $grid-gutter-width

// IMPORT SECTION

//@import 'node_modules/bootstrap/scss/bootstrap-reboot.scss' // Bootstrap Reboot collection
//@import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss' // Bootstrap Breakpoints mixin
//@import 'node_modules/bootstrap/scss/bootstrap-grid.scss' // Optional Bootstrap Grid
//@import 'node_modules/bootstrap/scss/_forms.scss'
//@import 'node_modules/bootstrap/scss/_buttons.scss'
//@import "node_modules/bootstrap/scss/_transitions.scss" // тут collaps
//@import 'node_modules/bootstrap/scss/bootstrap.scss'
//@import 'node_modules/swiper/swiper-bundle'
//@import 'node_modules/aos/src/sass/aos.scss'



// FONTS LOAD SECTION

@mixin font($fname, $fstyle, $fweight, $furl)
	@font-face
		font-family: $fname
		font-style: $fstyle
		font-weight: $fweight
		src: url($furl) format('woff2')
		font-display: swap

@include font('circ-oh5', normal, 400, '../fonts/circe_extralight.woff2')
@include font('roboto-oh5', normal, 400, '../fonts/roboto-regular-webfont.woff2')
@include font('roboto-oh5', italic, 400, '../roboto-italic-webfont.woff2')
@include font('roboto-oh5', normal, 700, '../roboto-bold-webfont.woff2')
@include font('roboto-oh5', italic, 700, '../roboto-bolditalic-webfont.woff2')

// GENERAL DOCUMENT STYLES

::placeholder
	color: #666

::selection
	background-color: $accent
	color: #fff

input, textarea
	outline: none
	&:focus:required:invalid
		border-color: red
	&:required:valid
		border-color: green

body
	font-family: $userfont
	font-size: $fontsize
	line-height: $lineheight
	color: $textcolor
	min-width: 320px
	position: relative
	overflow-x: hidden
