@font-face {
  font-family: "Monserrat";
  font-display: swap;
  src: url('/assets/fonts/Montserrat-Regular.woff2') format("woff2");
  font-weight: 400;
  font-style: normal
}
@font-face {
  font-family: "Monserrat";
  font-display: swap;
  src: url('/assets/fonts/Montserrat-Bold.woff2') format("woff2");
  font-weight: 700;
  font-style: normal
}

/* purgecss start ignore */
.hidden {
  opacity: 0;
}
.visible {
  opacity: 1;
  transition: 0.6s;
}
/* purgecss end ignore */

body::-webkit-scrollbar {
  width: 12px;               /* ширина всей полосы прокрутки */
}

body::-webkit-scrollbar-track {
  background: #fff;        /* цвет зоны отслеживания */
}

body::-webkit-scrollbar-thumb {
  background-color: $accent;    /* цвет бегунка */
  border-radius: 20px;       /* округлось бегунка */
  border: 1px solid #fff;  /* отступ вокруг бегунка */
}

img {
  width: 100%;
  height: auto;
}

.homeLink img {
  width: 200px;
  height: auto;
}
ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}
a:active, a:hover {
  outline-width: 0;
  color: #035e85;
  transition: .3s;
  text-decoration: none;
}
.menu-mobil {
  font-family: 'Monserrat';
  text-transform: uppercase;
  font-weight: 700;
}
h1 {
  padding: 0;
  margin: 0 0 1.45rem;
  color: $accent;
  text-rendering: optimizeLegibility;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
}
h2 {
  padding: 0;
  margin: 0 0 27px;
  text-rendering: optimizeLegibility;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 55px;
  color: $accent;
}
.wrap, main {
  display: flex;
}
main {
  flex-direction: column;
  min-height: calc(100vh - 261.1px);
}
.top {
  padding-top: 14px;
  padding-bottom: 18px;
  padding-left: calc((100% - 1184px) / 2);
  padding-right: calc((100% - 1184px) / 2);
  border-bottom: 1px solid #E0E0E0;
}
.top ul {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  gap: 30px;
  color: #828282;
  font-size: 14px;
}
.top ul li {
  margin: 0;
}
header {
  width: 100%;
  background-color: #fff;
  z-index: 100;
  position: sticky;
  top: -1px;
  border-bottom: 1px solid #eee;
}
.homeLink {
  display: flex;
  flex: 0 0 164px;
}
.logo {
  width: 155px;
}
.bars {
  display: none;
}
.close {
  display: none;
}
nav {
  display: flex;
  gap: 46px;
}
nav a {
  color: #009cdf;
  font-size: 0.8rem;
}
nav a:hover {
  color: $accent;
}
.search-mobil {
  display: none;
}
.search {
  display: flex;
  cursor: pointer;
  flex: 0 0 164px;
  justify-content: flex-end;
  color: #2F41DF;
}
.search svg:hover {
  color: #19237D;
  transition: 0.3s;
}
/* purgecss start ignore */
.slider-container {
  position: relative;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.slider-img {
  height: 400px;
  overflow: hidden;
}
.img-post img {
  height: auto;
}
.swiper-button-next, .swiper-button-prev {
  background: #BDBDBD;
  padding: 10px;
  border-radius: 50px;
  width: 45px;
  top:67px;
}
.swiper-button-next {
  left: calc((100% - 1184px)/ 2 + 350px);
}
.swiper-button-prev {
  left: calc((100% - 1184px)/ 2 + 290px);
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 18px;
  color: #19237d;
  font-weight: 600;
}
.slider-text {
  position: absolute;
  width: 300px;
  left: calc((100% - 1184px)/ 2);
  top: 25%;
  z-index: 3;
  height: 50%;
  overflow: hidden;
  padding: 0 10px;
}
.swiper-scrollbar-drag {
  background: #00ddf0;
}
.swiper-container-horizontal>.swiper-scrollbar {
  bottom: -15px;
}

.podlogka {
  position: absolute;
  background: $accent;
  height: 100%;
  width: 300px;
  top: 0;
  z-index: 2;
  left: calc((100% - 1184px)/ 2);
  opacity: 0.85;
}
.telephone {
  font-size: 1.8rem;
  text-align: center;
  padding-top: 20px;
  font-weight: 700;
  color: #012f42;
}
.telephone a:hover {
  color: #fff;
}
.baner {
  z-index: 3;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
/* purgecss end ignore */
h3.header {
  font-weight: bold;
  font-size: 44px;
  line-height: 55px;
  color: #fff;
  position: absolute;
  top: 36px;
  left: 48px;
  z-index: 3;
}
h4.header-2 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 6px;
}
.slider-but {
  position: absolute;
  bottom: 36px;
  width: 100%;
  text-align: center;
}
.baner-href {
  cursor: pointer;
  color: #ffffff;
  z-index: 3;
  padding: 5px 40px 8px;
  border-radius: 50px;
  border: 2px solid #fff;
}
.baner-href:hover {
  background: #035e85;
  transition: 0.6s;
}
.img-header-post {
  height: 400px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}
h1.header {
  font-size: 3rem;
  line-height: 3.65rem;
}
h1.header-post {
  position: absolute;
  color: #fff;
  left: calc((100% - 1184px) / 2);
  top: 200px;
  width: 1232px;
  z-index: 10;
}
.desk-baner p {
  font-size: 2rem;
  line-height: 24px;
}
.button-baner {
  color: #D90000;
  display: flex;
  padding: 14px 20px 10px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.pt-5 {
  padding-bottom: 5px;
}
.wrap {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: calc((100% - 1184px) / 2);
  padding-right: calc((100% - 1184px) / 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
main {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 257.76px);
}
.slide-main {
  width: 100%;
  display: flex;
}
.container {
  margin: 0 auto;
  padding: 24px 48px;
  width: 1280px;
}
.fluid {

}
.blue {
  background-color: $accent;
}
.vac {
  padding: 24px;
  border-radius: 2px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button-centr {
  margin: 40px 0 20px;
  justify-content: center;
  display: flex;
}
.button-green {
  cursor: pointer;
  color: #fff;
  background: $accent;
  padding: 5px 40px;
  border-radius: 2px;
}
.button-green:hover {
  background: #00b0bc;
  transition: .6s;
  color:#fff
}
.col-6 {
  width: calc((100% - 24px) / 2);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fit-content {
  height: fit-content;
}
.col-3 {
  width: calc((100% - 72px) / 4);
  margin-bottom: 44px;
  padding: 0;
  margin: 0;
  flex-shrink: unset;
}
.row {
  display: flex;
  justify-content: space-between;
}
.href-projects {
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
}
.mb-30 {
  margin-bottom: 30px;
}
.vdnh-project div {
  padding: 17px 35px;
  color: #fff;
  background-color: #D90000;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.531875rem;
}
.ml-5 {
  margin-left: 10px;
}

.ml-mb-5 {
  margin-left: 10px;
  position: relative;
  top: 3px;
}
.news-desc {
  background-color: #4f4f4f;
  color: #fff;
  padding: 24px 24px 52px;
}
.date {
  color: #d90000;
}
.fit-content p {
  margin: 0;
}
.mb-69 {
  margin-bottom: 69px;
}
.red {
  background-color: #d90000;
}
.nav-footer {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.nav-footer a {
  margin-bottom: 1rem;
}
.logo-footer {
  margin-bottom: 2rem;
}
.footer {
  padding-top: 28px;
  padding-bottom: 23.7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: calc((100% - 1184px) / 2);
  padding-right: calc((100% - 1184px) / 2);
  color: #d6d6d6;
  font-size: 16px;
  line-height: 20px;
}
.footer .red {
  padding-top: 50px;
  margin-bottom: 0;
}
.col-9 {
  position: relative;
}
.footer-contacts {
  color: #fff;
  position: absolute;
  top: 135px;
  right: 20px;
  line-height: 0rem;
  display: flex;
  flex-direction: column;
  height: 40%;
  justify-content: space-between;
}
.tel {
  font-weight: 700;
  font-size: 1.2rem;
}
.adress {
  font-weight: 400;
}
.map-footer {
  display: flex;
}
.img-map-footer {
  opacity: 0 !important;
}
.contact {
  padding: 50px;
  font-size: 20px;
}
.adress {
  padding: 0 50px;
}
footer .row {
  flex: 1 0 0;
  margin: 0;
  flex-wrap: nowrap;
}
.f2 {
  flex: 2 0 45%;
}
.vedomost-card {
  background-color: #e2f5fa;
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: 70px 1fr;
  grid-template-areas: "img text";
}
.vedomost-img {
  grid-area: img;
  overflow: hidden;
  height: 70px;
}
.vedomost-img img {
  width: 100%;
  height: 100%;
}
.vedomost-card p {
  grid-area: text;
}
.frm {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
.frm svg {
  width: 25px;
  cursor: pointer;
}
#cls {
  text-align: end;
}
@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1320px;
  }
}
@media (max-width: 1200px) {
  .podlogka, .slider-text {
    left: 0;
    width: 35%;
    min-width: 386px;
  }
  .swiper-button-prev {
    left: 260px;
  }
  .swiper-button-next {
    left: 320px;
  }
}
@media only screen and (max-width: 1279px) {
  .top {
    padding-left: 32px;
    padding-right: 32px;
  }
  .top ul {
    gap: 20px;
  }
  .homeLink {
    flex: 0 0 145px;
  }
  .homeLink svg {
    width: 145px;
  }
  nav {
    gap: 20px;
    font-size: 15px;
    justify-content: end;
  }
  .menu-mobil {
    flex: 1 0 0;
  }
  .search {
    flex: 0 0 0;
  }
  .wrap {
    padding-top: 23.7px;
    padding-bottom: 23.7px;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: space-between;
  }
  .slide-main {
    flex-direction: column;
    align-items: center;
  }
  .carousel.slide .flickity-prev-next-button.previous {
    top: calc(100vw / 3.2 - 60px);
    right: 80px;
    left: unset !important;
    color: #19237D;
    background: #fff;
  }
  .carousel.slide .flickity-prev-next-button.next {
    top: calc(100vw / 3.2 - 60px);
    right: 36px !important;
    color: #19237D;
    background: #fff;
  }
  #activity {
    margin-top: 0;
  }
  main {
    min-height: calc(100vh - 297px - 68px);
  }
  .img-baner-main {
    height: 700px;
  }
  h2 {
    font-size: 1.6875rem;
  }
  .container {
    margin: 0;
    padding: 53px 32px 24px;
    width: 100%;
    max-width: unset;
  }
  .row {
    flex-wrap: wrap;
  }
  .activity .col-3 {
    margin-bottom: 20px;
  }
  .col-6 {
    /*width: 100%;*/
  }
  h1.header {
    font-size: 2rem;
    line-height: 2rem;
  }
  .partner {
    width: calc(100% / 7);
  }
  img.img-partner {
    width: 100%;
    height: auto;
  }
  .part {
    align-items: center;
  }
  .map-footer {
    display: none;
  }
  .footer-contacts {
    position: static;
    line-height: 1rem;

  }
  .nav-footer {
    flex-direction: row;
    gap: 10px;
    width: calc(100% - 154px);
    justify-content: center;
  }
  .footer .red {
    padding-top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .logo-footer {
    margin-bottom: 0;
  }
  .footer {
    padding: 30px 32px;
    font-size: 15px;
  }
  .f1 svg {
    width: 145px;
  }
  .adress {
    padding: 0;
  }
  .f4 {
    order: 4;
  }
  .f3, .f4 {
    width: 30%;
    padding: 0;
    margin-bottom: 20px;
  }
  .f2, .f5 {
    width: 70%;
    padding: 0;
    margin-bottom: 20px;
  }
  footer .row {
    padding-left: 51px;
    flex-wrap: wrap;
  }
  .col-3.f5 div {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
}
@media only screen and (max-width: 767px) {
  body {
    font-size: 15px;
    line-height: 19px;
  }
  .top {
    display: none;
  }
  .mobil-none {
    display: none;
  }
  .search-mobil {
    display: block;
  }
  .search-mobil svg {
    width: 30px;
    height: 30px;
  }
  .menu-mobil {
    width: 100vw;
    height: 100vh;
    background-color: $accent;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: 0.3s;
    left: -1300px;
    opacity: 0;
    z-index: 11;
  }
  .ps-4 a {
    font-size: 1.5rem;
  }
  .bars {
    display: block;
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: $accent;
  }
  .close {
    display: block;
    position: absolute;
    width: 25px;
    right: 40px;
    top: 30px;
  }
  nav {
    display: flex;
    gap: 46px;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
  }
  nav a {
    color: #fff;
  }
  .wrap {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .homeLink {
    width: 120px;
  }
  .podlogka {
    position: relative;
    margin: -20px auto 0;
  }
  .slide-main {
    /*height: 480px;*/
    flex-direction: column;
    align-items: center;
  }
  .flickity-page-dots .dot {
    width: 58px !important;
    margin: 0 4px !important;
  }
  .carousel.slide .flickity-prev-next-button.previous {
    top: 45%;
    right: calc(50% + 10px) !important;
  }
  .carousel.slide .flickity-prev-next-button.next {
    top: 45%;
    right: calc(50% - 40px) !important;
  }
  h1 {
    font-size: 1.4rem;
    line-height: 28px;
    margin-top: 15px;
  }
  h1.header.col-6 {
    font-size: 1.875rem;
  }
  h2 {
    font-size: 22px;
    margin-bottom: 10px;
    line-height: 26px;
  }
  .img-baner-main {
    height: 400px;
  }
  .slider-text {
    bottom: 20px;
    top: unset;
    height: 150px;
    left: unset;
    right: 50px;
    width: 50%;
    padding: 0;
    min-width: unset;
  }
  .swiper-button-prev {
    top: unset;
    bottom: 90px;
    left: 80px;
  }
  .swiper-button-next {
    top: unset;
    bottom: 90px;
    left: 140px;
  }
  h3.header {
    font-size: 24px;
    line-height: 19px;
    margin: 0;
  }
  h4.header-2 {
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 0;
  }
  .desk-baner p {
    font-size: 2rem;
    margin-bottom: 13px;
  }
  .container {
    padding: 20px 20px 0;
  }
  .activity .col-3 {
    width: 100%;
    padding: 0;
  }
  .partner {
    width: 25%;
  }
  .footer {
    flex-direction: column;
    padding: 30px 20px;
    text-align: center;
    align-items: center;
  }
  .col-3.f5 div {
    justify-content: center;
  }
  .col-3 {
    width: 100%;
    flex: unset;
    max-width: unset;
  }
  footer .row {
    padding-left: 0;
    margin: 0;
  }
  .f4 {
    order: 2;
  }
  .f3 {
    order: 3;
  }
  .f2, .f3, .f4, .f5 {
    margin: 20px 0;
  }
  .tel {
    font-size: 1.5rem;
  }
}
.menu-vis {
  left: 0;
  opacity: 1;
}
@media only screen and (max-width: 620px) {
  .podlogka {
    height: 120px;
    width: 100%;
    margin: 0;
    min-width: unset;
  }
  .telephone {
    display: none;
  }
  .baner-href {
    left: unset;
    right: 20px;
  }
  h3.header {
    top: unset;
    bottom: 40px;
    left: 20px;
  }
  .swiper-button-next {
    display: none;
  }
  .swiper-button-prev {
    display: none;
  }
  .slider-text {
    width: 100%;
    left: 0;
    height: 80px;
    bottom: 45px;
  }
  .slider-img {
    height: 250px;
  }
  footer {
    margin-top: 40px;
  }
}
