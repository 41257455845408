.activity {
  margin-top: 44px;
}
.row.activity {
  margin: 0;
}
.activity .act {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #009cdf;
  color: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
  position: relative;
  flex-basis: unset;
  height: 100%;
}
.activity-title {
  display: flex;
  flex-direction: column;
}
#activity h3 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 33px;
  margin: 18px 24px;
}
.activity-desc {
  height: 140px;
}
.href-activity {
  width: 100%;
  position: absolute;
  height: 100%;
}
.href-activity:active {
  border: none;
  background: rgba(63, 81, 181, 0.34);
}
.img-activity-main {
  height: 200px;
  overflow: hidden;
  position: relative;
}
.post {
  margin-top: 40px;
}
.post img {
  width: 100%;
}
.img-activity-main img {
  all: inherit;
  bottom: 0;
  left: 0;
  margin: 0;
  max-width: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 767px) {
  .img-activity-main {
    height: 216px;
  }
  #activity {
    margin-top: 0;
  }
  #activity h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .activity-title {
    height: 284px;
  }
  .href-activity {
    padding: 0 24px 23px;
  }
  .post {
    margin-top: 0;
  }
  .img-header-post {
    height: auto !important;
  }
}