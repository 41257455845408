.vac-1 {
  margin-bottom: 33px;
}
.vac-open {
  border: none;
  background: transparent;
  color: #2F41DF;
  cursor: pointer;
  font-weight: 600;
  position: relative;
}
.vac-open::after {
  content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 320 512'><path fill='rgba%2847,65,223,1%29' d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'></path></svg>");
  transition: transform 0.35s ease;
  position: absolute;
  top: 5px;
}
.vac-open::before {
  content: 'Подробнее';
}
.vac-open[aria-expanded="true"]::before {
  content: 'Кратко';
}
.vac-open[aria-expanded="true"]::after {
  transform: rotate(180deg);
  top: -2px;
}
.vac-close {
  border: none;
  background: transparent;
  color: #2F41DF;
  cursor: pointer;
  font-weight: 600;
}
.vac-close::after {
  content: '>';
  display: inline-block;
  transform: rotate(270deg) scaleY(2) translate(0px, 3px);
  font-weight: 200;
  font-size: 13px;
}
.vac-1 ul {
  list-style: square;
}
.resume {
  font-size: 24px;
  color: #2F41DF;
  line-height: 30px;
}
.form-sticky {
  position: sticky;
  top: 200px;
}
.form-control {
  background: #F2F2F2;
}
.submit {
  background: $accent;
  color: #fff;
}
.submit:hover {
  background: #035e85;
  color: #fff;
}
.submit:active {
  background: #19237D;
  color: #fff;
}