#map-objects {
  position: relative;
}
#map-objects img {
  width: 100%;
  height: auto;
}
#slider-projects {
  overflow: hidden;
}
.slider-container img {
  all: inherit;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
}
.flex {
  display: flex;
  gap: 10px;
}
.but {
  background: #2F41DF;
  color: #fff;
  padding: 10px 45px;
  border-radius: 5px;
  font-size: 20px;
  height: 55px;
  display: flex;
  align-items: center;
}
.but:hover {
  background: #2C3BBF;
  color: #fff;
}
.but:active {
  background: #19237D;
}
.carousel-cell {
  width: 100%;
  font-weight: 700;
  margin-right: 10px;
}
.img-carousel-projects {
  overflow: hidden;
  border-radius: 5px;
}
.project-title {
  font-size: 20px;
  line-height: 25px;
  padding-top: 10px;
}
.project-title .row {
  margin-top: 5px;
}
.project-desc {
  padding: 10px;
  font-weight: 400;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}
.swiper-small {
  margin-top: 6px;
  height: 160px;
}
.slider-inner {
  margin-top: 10px;
}
.carousel-inner {
  width: calc((100% - 60px) / 4);
}
.swiper-container {
  height: 670px;
  overflow: hidden;
}
.swiper-button-next,
.swiper-button-prev {
  background: #fff;
  padding: 10px;
  border-radius: 50px;
  width: 45px;
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 20px;
  color: #009cdf;
  font-weight: 600;
}
.img-carousel-swiper {
  height: 100%;
  width: auto;
}
img.img-carousel-swiper {
  /* height: 100%; */
  width: 100%;
}
.swiper-slide {
  overflow: hidden;
}
.thumb {
  margin-top: 20px;
  overflow: hidden;
  height: 158px;
}
.img-carousel-swiper-thumb {
  height: 100%;
  width: auto;
}
img.img-carousel-swiper-thumb {
  /* height: 100%; */
  width: 100%;
}
.swiper-slide-thumb-active {
  border-bottom: 5px solid green;
  opacity: 0.5;
}
.zagolovok {
  padding-left: 200px;
  background: #fff;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#map {
  position: relative;
}
img.map {
  height: auto;
}
.pins svg {
  width: 27px;
  height: 27px;
  cursor: pointer;
}
.pin-1 {
  position: absolute;
  top: 65%;
  left: 22.5%;
}
.pin-2 {
  position: absolute;
  top: 67%;
  left: 57%;
}
.pin-3 {
  position: absolute;
  top: 66%;
  left: 51%;
}
.pin-4 {
  position: absolute;
  top: 25%;
  left: 63.2%;
}
.pin-5 {
  position: absolute;
  top: 1.1%;
  left: 53.5%;
}
.pin-6 {
  position: absolute;
  top: 24%;
  left: 34.7%;
}
.pin-7 {
  position: absolute;
  top: 16%;
  left: 62.5%;
}
.pin-8 {
  position: absolute;
  top: 73%;
  left: 64%;
}
.big {
  transform: scale(2) translateY(-5px);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.82, 0.25, 0.49, 2.38);
}
.swiper-container-main {
  height: auto;
}
.buttons-slide {
  position: absolute;
  top: 20px;
  gap: 10px;
  z-index: 11;
  left: 100px;
}
.swiper-pagination-fraction {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 170px;
  font-size: 20px;
  color: #B3B3B3;
  background: #fff;
  text-align: left;
  padding-left: 30px;
  padding-top: 20px;
}
.swiper-button-disabled {
  opacity: 0.8;
}
.buttons-slide div {
  cursor: pointer;
}
.slider-all-projects-text {
  height: 180px;
  overflow: hidden;
}
.swiper-big {
  position: relative;
  height: 650px;
  overflow: hidden;
}
.swiper-project-button-next, .swiper-project-button-prev {
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
}
.swiper-project-button-next {
  right: 20px;
}
.swiper-project-button-prev {
  left: 20px;
}
span.font-sz {
  font-size: 12px;
  line-height: 6px !important;
}


@media only screen and (max-width: 1279px) {
  .carousel-inner {
    width: calc((100% - 60px) / 4);
  }
  .thumb {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .but {
    padding: 5px 20px;
  }
  .flex {
    justify-content: space-between;
  }
  .carousel.projects {
    margin-top: 60px;
  }
  .flickity-prev-next-button.previous {
    top: -35px;
    left: 100px !important;
  }
  .flickity-prev-next-button.next {
    top: -35px;
    right: 100px !important;
  }
  .carousel-projects {
    font-size: 17px;
    line-height: 21px;
  }
  h4.project-title {
    font-size: 18px;
    line-height: 24px;
  }
  .projects-title .row {
    flex-wrap: wrap;
    gap: 10px;
  }
  .img-carousel-projects {
    height: auto;
  }
  .projects-title .row .gatsby-image-wrapper {
    width: 48.4%;
  }
  .swiper-container {
    height: 180px;
  }
  .thumb {
    height: 100px;
    margin-top: 10px;
  }
  .swiper-button-next, .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    font-size: 15px;
  }
  .swiper-container-main {
    height: auto;
  }
  .swiper-pagination-fraction {
    background: transparent;
    position: relative;
    height: 50px;
  }
  .zagolovok {
    height: auto;
    padding: 0;
  }
  .pins svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
  .swiper-big {
    height: 300px;
  }
  .swiper-small {
    height: 80px;
  }
}
@media only screen and (max-width: 450px) {
  .slider-all-projects-text {
    height: 300px;
  }
  .swiper-pagination-fraction {
    height: 80px;
    padding: 30px;
  }
  .buttons-slide {
    top: unset;
    bottom: 288px;
    left: 150px;
  }
  .swiper-big {
    height: 200px;
  }
  .swiper-small {
    height: 60px;
  }
}
